import { RichText } from '@sprinklr/shared-lib';
import { RichTextObject } from '@sprinklr/shared-lib/@types/entities';
import React, { ReactElement } from 'react';
import { Text, ThemeProvider, ThemeUICSSObject } from 'theme-ui';

type Props = {
  descriptionText: RichTextObject;
  descriptionSx: ThemeUICSSObject;
};

const DescriptionTextRenderer = ({
  descriptionSx,
  descriptionText,
}: Props): ReactElement => {
  return (
    <ThemeProvider
      theme={{
        styles: {
          p: descriptionSx,
        },
      }}
    >
      <RichText richTextObject={descriptionText} />
    </ThemeProvider>
  );
};

export default DescriptionTextRenderer;

import { useEffect } from 'react';

import useToggle from 'react-use/lib/useToggle';

//Ref: https://reactjs.org/docs/concurrent-mode-patterns.html
//React is also working on this under experimental feature useTransition for stateUpdate/rendering
const useTransition = (delay?: number): boolean => {
  const [show, updateShow] = useToggle(false);

  useEffect(() => {
    if (!delay) {
      const animationTaskId = requestAnimationFrame(() =>
        requestAnimationFrame(updateShow),
      );

      return () => cancelAnimationFrame(animationTaskId);
    }

    const timeOutId = setTimeout(updateShow, delay);

    return () => clearTimeout(timeOutId);
  }, []);

  return show;
};

export default useTransition;

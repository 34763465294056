import { useEffect } from 'react';

import useToggle from 'react-use/lib/useToggle';

export const scheduleWhenIdle = (
  fn: () => void,
  options?: IdleRequestOptions,
) => {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(fn, options ?? {});
  }
  return window.requestAnimationFrame(fn);
};

export const cancelScheduledIdleCallback = (id): void => {
  if (window.cancelIdleCallback) {
    return window.cancelIdleCallback(id);
  }
  return window.cancelAnimationFrame(id);
};

const useInteraction = ({
  delay,
  timeout,
}: {
  delay?: number;
  timeout?: number;
}): boolean => {
  const [show, updateShow] = useToggle(false);

  useEffect(() => {
    if (delay) {
      const timeOutId = setTimeout(updateShow, delay);

      return () => clearTimeout(timeOutId);
    }

    const taskId = scheduleWhenIdle(
      updateShow,
      timeout ? { timeout } : undefined,
    );

    return () => cancelScheduledIdleCallback(taskId);
  }, []);

  return show;
};

export default useInteraction;

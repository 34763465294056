import React from 'react';

import { HorizontalTabbedCarousal } from './components/variants/horizontal';
import { VerticalTabbedCarousal } from './components/variants/vertical';
import { MobileView } from './components/variants/MobileView';

import { useResponsiveValue } from '@theme-ui/match-media';

import { Section } from './types';
import {
  ContainerBottomPadding,
  ContainerTopPadding,
} from '@sprinklr/shared-lib/@types/types';
import { Variant } from './constants';

type TabbedCarousalProps = {
  title: string;
  tabDescription: string;
  tabItems: Section[];
  icon: {
    icon: string;
  };
  variant?: Variant;
  subTitle?: string;
  subDescription?: string;
  tag?: string;
  paddingTop?: ContainerTopPadding;
  paddingBottom?: ContainerBottomPadding;
};

export const TabbedCarousal = ({
  title,
  tabDescription,
  tabItems,
  icon,
  subTitle,
  subDescription,
  tag,
  variant = Variant.HORIZONTAL,
  paddingTop,
  paddingBottom,
}: TabbedCarousalProps) => {
  const isMobile = useResponsiveValue([true, true, false]);

  if (isMobile) {
    return (
      <MobileView
        title={title}
        description={tabDescription}
        icon={icon}
        tabItems={tabItems}
        subTitle={subTitle}
        subDescription={subDescription}
        tag={tag}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
      />
    );
  }

  return variant === Variant.VERTICAL ? (
    <VerticalTabbedCarousal
      title={title}
      description={tabDescription}
      tabItems={tabItems}
      subTitle={subTitle}
      tag={tag}
      icon={icon}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    />
  ) : (
    <HorizontalTabbedCarousal
      title={title}
      description={tabDescription}
      tabItems={tabItems}
      icon={icon}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    />
  );
};

import React, { useMemo } from 'react';

import { Box, Text } from 'theme-ui';
import { Carousal } from './horizontal/Carousal';
import { Title } from '@sprinklr/shared-lib/components/Title';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import { Section } from '../../types';
import {
  ContainerBottomPadding,
  ContainerTopPadding,
} from '@sprinklr/shared-lib/@types/types';

type Props = {
  title: string;
  description: string;
  tabItems: Section[];
  icon: {
    icon: string;
  };
  subTitle?: string;
  subDescription?: string;
  tag?: string;
  paddingTop?: ContainerTopPadding;
  paddingBottom?: ContainerBottomPadding;
};

export const MobileView = ({
  title,
  description,
  tabItems,
  icon,
  subTitle,
  subDescription,
  tag,
  paddingTop,
  paddingBottom,
}: Props) => {
  const [pt, pb] = useContainerPadding({
    top: paddingTop ?? 'NONE',
    bottom: paddingBottom ?? 'NONE',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: '16px',
        pt,
        pb,
      }}
    >
      <Title
        title={title}
        description={description}
        icon={icon}
        sx={{ marginBottom: ['20px', '32px', '72px'] }}
      />
      {tag ? (
        <Text
          sx={{
            fontSize: '15px',
            lineHeight: '18px',
            color: 'primary',
            fontWeight: 600,
            marginBottom: '6px',
            textAlign: 'center',
          }}
        >
          {tag}
        </Text>
      ) : null}
      {subTitle ? (
        <Text
          sx={{
            fontSize: '20px',
            lineHeight: '24px',
            textAlign: 'center',
            fontWeight: 700,
            marginBottom: '8px',
            marginX: 'auto',
          }}
        >
          {subTitle}
        </Text>
      ) : null}
      {subDescription ? (
        <Text
          sx={{
            fontSize: '15px',
            lineHeight: '22px',
            fontWeight: 400,
            marginBottom: '32px',
            textAlign: 'center',
            color: 'sprText02',
            marginX: 'auto',
          }}
        >
          {subDescription}
        </Text>
      ) : null}
      <Carousal sections={tabItems} />
    </Box>
  );
};

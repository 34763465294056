import React, { useContext, useEffect, useRef, useState } from 'react';

import { Box } from 'theme-ui';
import { ImageBlock } from '@sprinklr/shared-lib';

import useWindowSize from 'react-use/lib/useWindowSize';

import { ThemeUIStyleObject } from 'theme-ui';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import PageContext from '@sprinklr/shared-lib/context/pageContext';

const DEFAULT_INSET_INLINE_END = 100;
const PADDING = 8;

export const FloatingImage = ({
  image,
  sx,
}: {
  image: {
    title?: string;
    description?: string;
    gatsbyImageData: IGatsbyImageData;
  };
  sx?: ThemeUIStyleObject;
}) => {
  const [el, setEl] = useState<HTMLElement | null>();
  const [insetInlineEnd, setInsetInlineEnd] = useState<number>(
    DEFAULT_INSET_INLINE_END,
  );
  const { width } = useWindowSize();

  const { isPageRTL } = useContext(PageContext);

  useEffect(() => {
    if (el) {
      const { left, right } = el.getBoundingClientRect();
      setInsetInlineEnd(
        Math.min(
          DEFAULT_INSET_INLINE_END,
          isPageRTL ? width - right - PADDING : left - PADDING,
        ),
      );
    }
  }, [width, el]);

  return (
    <Box ref={setEl} sx={{ position: 'relative', ...sx }}>
      <Box
        sx={{
          height: '100%',
          top: '0px',
          position: 'absolute',
          insetInlineStart: '0px',
          insetInlineEnd: `-${insetInlineEnd}px`,
        }}
      >
        <ImageBlock
          imageSx={{
            objectFit: 'scale-down',
            height: '100%',
            width: '100%',
            borderRadius: '16px',
          }}
          image={image}
          altTitle={image.title ?? image.description}
        />
      </Box>
    </Box>
  );
};

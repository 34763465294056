import { ThemeUIStyleObject } from 'theme-ui';

export const getCarousalTitleStyles = ({
  ind,
  activeSection,
  totalSections,
}: {
  ind: number;
  activeSection: number;
  totalSections: number;
}): ThemeUIStyleObject => ({
  height: '70px',
  borderRadius: '0px',
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: `${100 / totalSections}%`,
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  fontSize: ['14px', '14px', '18px !important'],
  lineHeight: ['17px', '17px', '22px !important'],
  paddingX: '12px',
  fontWeight: 600,
  background: '#fff',
  color: 'black',
  borderWidth: '0px',
  position: 'relative',
  textTransform: 'none',
  ':hover': {
    background: '#107EFF15',
  },
  ...(ind === 0 ? { borderStartStartRadius: '12px' } : undefined),
  ...(ind === totalSections - 1 ? { borderStartEndRadius: '12px' } : undefined),
  ...(ind === activeSection
    ? {
        background: '#107EFF15',
        color: '#107EFF',
      }
    : undefined),
});

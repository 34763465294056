import * as React from 'react';
import _isFunction from 'lodash/isFunction';

import useTransition from '../hooks/useTransition';
import useInteraction from '../hooks/useInteraction';

type FallbackRenderer = () => JSX.Element;

type Fallback = JSX.Element | null | FallbackRenderer;

export enum VariantsType {
  TRANSITION = 'TRANSITION',
  INTERACTION = 'INTERACTION',
}

export type VariantProps = {
  delay?: number;
  timeout?: number;
  children: JSX.Element;
  fallback: Fallback;
};

export const RECOMMENDED_DELAY = 10000;

function renderFallback(fallback: Fallback): JSX.Element | null {
  return _isFunction(fallback) ? fallback() : fallback;
}

function SuspendedRenderInteractionVariant({
  delay,
  timeout,
  children,
  fallback,
}: VariantProps): JSX.Element | null {
  const show = useInteraction({ delay, timeout });

  return show ? children : renderFallback(fallback);
}

function SuspendedRenderTransitionVariant({
  delay,
  children,
  fallback,
}: VariantProps): JSX.Element | null {
  const show = useTransition(delay);

  return show ? children : renderFallback(fallback);
}

function SuspendedRender({
  delay,
  children,
  fallback = null,
  variant = VariantsType.TRANSITION,
}: VariantProps & { variant?: VariantsType }): JSX.Element {
  const VariantComponent =
    variant === VariantsType.TRANSITION
      ? SuspendedRenderTransitionVariant
      : SuspendedRenderInteractionVariant;

  return (
    <VariantComponent delay={delay} fallback={fallback}>
      {children}
    </VariantComponent>
  );
}

export { SuspendedRender };

import React, { useState } from 'react';

import { Flex, Text, Box, Button } from 'theme-ui';
import { FloatingImage } from '../../FloatingImage';
import { ImageBlock } from '@sprinklr/shared-lib';
import {
  SuspendedRender,
  VariantsType,
} from '@sprinklr/shared-lib/components/SuspendedRender';

import { useResponsiveValue } from '@theme-ui/match-media';

import { getCarousalTitleStyles } from './helper';

import { Section } from '../../../types';
import DescriptionTextRenderer from '../../DescriptionTextRenderer';

export const Carousal = ({ sections }: { sections: Section[] }) => {
  const [activeSection, setActiveSection] = useState(0);

  const isMobileView = useResponsiveValue([true, true, false]);

  const selectedSection = sections[activeSection];

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
        marginX: 'auto',
        borderRadius: '12px',
        boxShadow: '8px 8px 44px rgba(0, 0, 0, 0.14)',
        background: '#fff',
      }}
      data-element-id="tabbed-carousel-section"
    >
      <Flex
        sx={{
          flexDirection: 'row',
          width: '100%',
          borderWidth: '0px',
          borderBottomWidth: ['0px', '0px', '1px'],
          borderStyle: 'solid',
          borderColor: '#9B9B9B20',
        }}
      >
        {sections.map((section, ind) => (
          <>
            <Button
              key={section.id}
              onClick={() => setActiveSection(ind)}
              sx={getCarousalTitleStyles({
                ind,
                activeSection,
                totalSections: sections.length,
              })}
              as="h3"
              data-tab-index={ind}
            >
              <>
                {section.tabName}
                {ind === activeSection && !isMobileView ? (
                  <Box
                    sx={{
                      width: '100%',
                      position: 'absolute',
                      bottom: '0px',
                      left: '0px',
                      height: '3px',
                      background: '#107EFF',
                    }}
                    as="span"
                  />
                ) : null}
              </>
            </Button>
            {/* load all non-selected section images too */}
            <SuspendedRender variant={VariantsType.INTERACTION} fallback={null}>
              <div style={{ height: '0px', overflow: 'hidden' }}>
                <FloatingImage image={section.image} />
              </div>
            </SuspendedRender>
          </>
        ))}
      </Flex>
      {isMobileView ? (
        <Flex sx={{ flexDirection: 'column', padding: '16px' }}>
          <Text
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '22px',
              marginBottom: '8px',
            }}
          >
            {selectedSection.title}
          </Text>
          <DescriptionTextRenderer
            descriptionText={selectedSection.descriptionText}
            descriptionSx={{
              fontSize: '15px',
              lineHeight: '22px',
              fontWeight: 400,
              color: 'darkGrey',
              marginBottom: '16px',
            }}
          />
          <ImageBlock
            imageSx={{
              objectFit: 'scale-down',
              width: '100%',
              borderRadius: '16px',
            }}
            image={selectedSection.image}
            altTitle={
              selectedSection.image.title ?? selectedSection.image.description
            }
          />
        </Flex>
      ) : (
        <Flex
          sx={{
            flexDirection: 'row',
            height: '500px',
            width: '100%',
            paddingY: '20px',
          }}
          data-element-id={`tab-content-${activeSection}`}
        >
          <Flex
            sx={{
              flexBasis: '40%',
              flexGrow: 0,
              flexShrink: 0,
              flexDirection: 'column',
              gridGap: '16px',
              paddingInlineStart: '36px',
              justifyContent: 'center',
            }}
          >
            <Text
              sx={{
                fontWeight: 700,
                fontSize: '32px',
                lineHeight: '38px',
                maxWidth: '350px',
              }}
            >
              {selectedSection.title}
            </Text>
            <DescriptionTextRenderer
              descriptionText={selectedSection.descriptionText}
              descriptionSx={{
                fontSize: '16px',
                lineHeight: '22px',
                color: 'darkGrey',
                maxWidth: '350px',
              }}
            />
          </Flex>
          <FloatingImage
            sx={{ flexBasis: '60%', flexGrow: 0, flexShrink: 0 }}
            image={selectedSection.image}
          />
        </Flex>
      )}
    </Flex>
  );
};

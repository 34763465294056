import React, { useMemo } from 'react';

import { Box } from 'theme-ui';
import { Title } from '@sprinklr/shared-lib/components/Title';
import { Carousal } from './Carousal';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import { Section } from '../../../types';
import {
  ContainerBottomPadding,
  ContainerTopPadding,
} from '@sprinklr/shared-lib/@types/types';

type Props = {
  title: string;
  description: string;
  tabItems: Section[];
  icon: {
    icon: string;
  };
  subTitle?: string;
  tag?: string;
  paddingTop?: ContainerTopPadding;
  paddingBottom?: ContainerBottomPadding;
};

export const VerticalTabbedCarousal = ({
  title,
  description,
  tabItems,
  icon,
  tag,
  subTitle,
  paddingTop,
  paddingBottom,
}: Props) => {
  const [pt, pb] = useContainerPadding({
    top: paddingTop ?? 'NONE',
    bottom: paddingBottom ?? 'NONE',
  });

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gridGap: '72px', pt, pb }}
    >
      <Title title={title} description={description} icon={icon} />
      <Carousal sections={tabItems} tag={tag} title={subTitle} />
    </Box>
  );
};

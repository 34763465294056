import React, { useState } from 'react';

import { Flex, Text, Box, Button } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { FloatingImage } from '../../FloatingImage';

import { getCarousalTabStyles } from './helper';

import { Section } from '../../../types';
import DescriptionTextRenderer from '../../DescriptionTextRenderer';

export const Carousal = ({
  sections,
  tag,
  title,
}: {
  sections: Section[];
  tag: string | undefined;
  title: string | undefined;
}) => {
  const [activeSection, setActiveSection] = useState(0);

  const selectedSection = sections[activeSection];

  return (
    <Flex
      sx={{ gridGap: '40px', height: '100%', width: '100%', marginX: 'auto' }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          flexBasis: '35%',
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        {tag ? (
          <Text
            sx={{
              fontSize: '18px',
              lineHeight: '22px',
              color: 'primary',
              fontWeight: 600,
              marginBottom: '6px',
            }}
          >
            {tag}
          </Text>
        ) : null}
        {title ? (
          <Text
            sx={{
              fontSize: '32px',
              lineHeight: '38px',
              fontWeight: 700,
              marginBottom: '16px',
            }}
          >
            {title}
          </Text>
        ) : null}
        {sections.map(({ title, icon, descriptionText, id }, ind) => (
          <Button
            key={id}
            onClick={() => setActiveSection(ind)}
            sx={getCarousalTabStyles(ind === activeSection)}
          >
            <Flex
              sx={{
                height: '22px',
                width: '22px',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  borderRadius: '50%',
                  background: '#107EFF',
                  opacity: '0.1',
                }}
              />
              {icon ? (
                <Icon
                  iconName={icon.icon}
                  iconSx={{
                    height: '12px',
                    width: '12px',
                    path: { fill: '#107EFF' },
                    fill: '#107EFF',
                    color: '#107EFF',
                  }}
                />
              ) : null}
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                flexGrow: 1,
                minWidth: 0,
                flexBasis: 0,
              }}
            >
              <Text
                sx={{
                  fontSize: '16px',
                  lineHeight: '26px',
                  fontWeight: 700,
                  color: 'sprText02',
                  textTransform: 'capitalize',
                }}
              >
                {title}
              </Text>
              <DescriptionTextRenderer
                descriptionText={descriptionText}
                descriptionSx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 400,
                  color: 'sprText02',
                  textTransform: 'capitalize',
                }}
              />
            </Flex>
          </Button>
        ))}
      </Flex>
      <FloatingImage
        sx={{
          flexGrow: 1,
          flexBasis: 0,
          minWidth: 0,
          background: '#F8F8FA',
          borderRadius: '12px',
        }}
        image={selectedSection.image}
      />
    </Flex>
  );
};

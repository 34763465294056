import { ThemeUIStyleObject } from 'theme-ui';

export const getCarousalTabStyles = (isActiveSection: boolean): ThemeUIStyleObject => ({
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  flexGap: '8px',
  gridGap: '8px',
  cursor: 'pointer',
  paddingX: '12px !important',
  paddingY: '8x',
  fontWeight: 600,
  background: '#fff',
  color: 'black',
  textAlign: 'left',
  borderWidth: '0px',
  marginBottom: '14px',
  textTransform: 'none',
  backgroundColor: '#fff',
  alignItems: 'start',
  boxShadow: '0px 1px 4px rgba(10, 10, 20, 0.15)',
  ':hover': {
    background: '#107EFF15',
  },
  ...(isActiveSection ? { boxShadow: '0px 0px 0px 1px #0E61F6 , 0px 0px 0px 4px #0E61F620' } : undefined),
});
